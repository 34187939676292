module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"cristian dan","short_name":"mk","start_url":"/","background_color":"#e7eef4","theme_color":"#e7eef4","icon":"src/images/favicon.png","display":"standalone","cache_busting_mode":"name","theme_color_in_head":false,"legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"df582601a8acf3d1680f21a26a4e11b6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
